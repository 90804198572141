import MapStyle from './MapStyle';

interface Mappable {
  location: {
    lat: string;
    lng: string;
  };
  zoom: string;
  center: {
    lat: string;
    lng: string;
  };
  pin: {
    url: string;
    width: string;
    height: string;
  };
}

const mapOptions: google.maps.MapOptions = {
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  scrollwheel: false,
  fullscreenControl: false,
  zoomControl: false,
  styles: MapStyle,
};

class Map {
  private readonly mapDIV: HTMLElement;
  private googleMap: google.maps.Map;
  private markerData: Mappable;
  private zoomIn: HTMLElement = document.querySelector('.map__zoom--in');
  private zoomOut: HTMLElement = document.querySelector('.map__zoom--out');

  constructor(el: string) {
    this.mapDIV = document.querySelector(el);
    if (this.mapDIV) {
      this.markerData = JSON.parse(this.mapDIV.dataset.mapMarker);
      this.init();
    }
  }

  init() {
    const { lat, lng } = this.markerData.center;
    const center = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

    this.googleMap = new google.maps.Map(document.getElementById('map'), {
      center,
      zoom: parseInt(this.markerData.zoom, 10),
      ...mapOptions,
    });

    this.addMarker();
    this.setupZoom();
  }

  addMarker(): void {
    const { lat, lng } = this.markerData.location;
    const { url, width, height } = this.markerData.pin;
    const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

    const marker = new google.maps.Marker({
      position: latLng,
      map: this.googleMap,
      animation: google.maps.Animation.DROP,
      icon: {
        url,
        scaledSize: new google.maps.Size(
          parseInt(width, 10),
          parseInt(height, 10)
        ),
      },

      optimized: false,
      clickable: false,
    });
  }

  setupZoom(): void {
    if (this.zoomIn && this.zoomOut) {
      this.zoomIn.addEventListener('click', (e: Event): void => {
        e.preventDefault();
        this.googleMap.setZoom(this.googleMap.getZoom() + 1);
      });
      this.zoomOut.addEventListener('click', (e: Event): void => {
        e.preventDefault();
        this.googleMap.setZoom(this.googleMap.getZoom() - 1);
      });
    }
  }
}

export default Map;
