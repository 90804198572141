declare var tableOverflowText: any;

export default {
  init() {
    const textContainer = document.querySelectorAll('.text-container');
    const tablesArray = [];
    for (let i = 0; i < textContainer.length; i = i + 1) {
      const tables = textContainer[i].querySelectorAll('table');
      for (let i = 0; i < tables.length; i = i + 1) {
        tablesArray.push(tables[i]);
      }
    }

    for (let i = 0; i < tablesArray.length; i = i + 1) {
      const table: any = tablesArray[i];
      const wrapper: any = document.createElement('div');
      wrapper.classList.add('table-wrap');
      wrapper.dataset.overflowtext = tableOverflowText;
      wrap(table, wrapper);
    }

    function wrap(el: any, wrapper: any) {
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
    }
  },
};
