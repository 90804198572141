export default {
  init() {
    const buttons = document.querySelectorAll('.js-button-show-more');

    if (buttons.length > 0) {
      for (let i = 0; i < buttons.length; i = i + 1) {
        const container = buttons[i].closest('.js-mob-hidden-container');
        const hiddenBlocks = container.querySelectorAll('.mob-hidden');
        buttons[i].addEventListener('click', () => {
          if (hiddenBlocks.length > 0) {
            for (let i = 0; i < hiddenBlocks.length; i = i + 1) {
              hiddenBlocks[i].classList.add('mob-hidden--shown');
            }
          }
          buttons[i].classList.add('is-hidden');
        });
      }
    }
  },
};
