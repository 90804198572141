const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  init() {
    const burger = document.getElementById('burger');
    const menu = document.getElementById('main-menu');

    burger.addEventListener('click', () => {
      if (burger.classList.contains('is-open')) {
        burger.classList.remove('is-open');
        menu.classList.remove('is-open');
        bodyScrollLock.enableBodyScroll(menu);
      } else {
        burger.classList.add('is-open');
        menu.classList.add('is-open');
        bodyScrollLock.disableBodyScroll(menu);
      }
    });

    function checkWidth(x) {
      if (x.matches) {
      } else {
        if (burger.classList.contains('is-open')) {
          burger.classList.remove('is-open');
          menu.classList.remove('is-open');
          bodyScrollLock.enableBodyScroll(menu);
        }
      }
    }

    var x = window.matchMedia('(max-width: 1023px)');
    checkWidth(x);
    x.addListener(checkWidth);
  },
};
