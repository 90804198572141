export default {
  init() {
    const tooltip = document.getElementById('tooltip');
    const tooltipTextHolder = document.getElementById('tooltip-country-name');
    const activeContainer = document.getElementById('active');
    const activeCountries = activeContainer.querySelectorAll(
      '[data-country-name]'
    );

    for (let i = 0; i < activeCountries.length; i = i + 1) {
      activeCountries[i].addEventListener('mousemove', (event) => {
        console.log(event.clientX);
        tooltip.style.left = event.clientX + 15 + 'px';
        tooltip.style.top = event.clientY - 15 + 'px';
      });

      activeCountries[i].addEventListener('mouseenter', (event) => {
        const countryName = activeCountries[i].dataset.countryName;
        tooltipTextHolder.innerHTML = countriesNames[countryName];
        tooltip.style.display = 'block';
      });
      activeCountries[i].addEventListener('mouseleave', (event) => {
        tooltip.style.display = 'none';
      });
    }
  },
};
