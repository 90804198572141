export default {
  init() {
    const selectors = document.querySelectorAll('[data-selector]');
    const cards = document.querySelectorAll('[data-card]');
    const cardsContainer = document.querySelector('.js-cards-container');
    const activeCard = document.querySelector(
      '.tabs-selector__card--is-active'
    );

    activeCard.style.opacity = '1';

    for (let i = 0; i < selectors.length; i++) {
      selectors[i].addEventListener('mouseover', function () {
        removeSiblingClass();
        this.classList.add('is-active');
        openCard(this.dataset.selector);
      });
    }

    let containerHeight = 0;
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].scrollHeight > containerHeight) {
        containerHeight = cards[i].scrollHeight;
      }
    }
    cardsContainer.style.height = containerHeight + 'px';

    function openCard(n) {
      const targetCard = document.querySelector(`[data-card="${n}"]`);
      for (let i = 0; i < cards.length; i++) {
        // cards[i].style.maxHeight = null;
        cards[i].style.opacity = '0';
      }
      // targetCard.style.maxHeight = targetCard.scrollHeight + 'px';
      targetCard.style.opacity = '1';
    }

    function removeSiblingClass() {
      for (let i = 0; i < selectors.length; i++) {
        selectors[i].classList.remove('is-active');
      }
    }
  },
};
