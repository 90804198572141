import axios from 'axios';

export default {
  inputsEvents() {
    const textInputs = document.querySelectorAll('[data-text]');
    const fileInputs = document.querySelectorAll('[data-file]');
    const $self = this;
    const changeEvent = new Event('change');

    for(let i = 0; i < textInputs.length; i++) {
      textInputs[i].addEventListener('change', function(e) {
        if(this.value.trim().length > 0) {
          this.parentElement.classList.add('full');
        } else {
          this.parentElement.classList.remove('full');
        }

        if(this.closest('.form-input').classList.contains('form-input--has-error')) {
          this.closest('.form-input').classList.remove('form-input--has-error');
        }
      })
    }

    for(let i = 0; i < textInputs.length; i++) {
      textInputs[i].addEventListener('focusin', function(e) {
        this.parentElement.classList.add('focus');
      })
    }

    for(let i = 0; i < textInputs.length; i++) {
      textInputs[i].addEventListener('focusout', function(e) {
        this.parentElement.classList.remove('focus');
      })
    }

    for(let i = 0; i < fileInputs.length; i++) {
      fileInputs[i].addEventListener('change', function(e) {
        const parent = this.closest('.form-input--file');
        const targetInput = parent.querySelector('[data-text]');
        targetInput.value = $self.fileName( this.value ) ;
        targetInput.dispatchEvent(changeEvent);
      })
    }
  },
  fileName(fullPath) {
    if (fullPath) {
      var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      var filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }
      return filename;
    } else {
      return '';
    }
  },
  initForm() {
    const asyncForm = document.querySelectorAll('[data-async]');

    for(let i = 0; i < asyncForm.length; i++) {
      asyncForm[i].addEventListener('submit', function(e) {
        e.preventDefault();
        const $form = this;
        const formData = new FormData($form);
        const options = {
          method: $form.dataset.method,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData,
          url: $form.dataset.action,
        };

        $form.closest('.form').classList.add('is-loading');

        axios(options)
          .then(response => {
            console.log("Success response:", response.data);
            if(response.data.status === 'success') {
              setTimeout(function(){
                $form.closest('.form').classList.remove('is-loading');
                showMessage($form, response.data);
              }, 500)
            } else if (response.data.status === 'badfields') {
              setTimeout(function(){
                $form.closest('.form').classList.remove('is-loading');
                badFields($form, response.data);
              }, 500)
            } else if (response.data.status === 'error') {
              setTimeout(function(){
                $form.closest('.form').classList.remove('is-loading');
                showMessage($form, response.data);
              }, 500)
            }
          })
          .catch(error => {
            console.log("Error response:", error);
        });
      })
    }

    const showMessage = (f, resp) => {
      const messageCont = f.querySelector('.resp-message');
      const messageTitle = messageCont.querySelector('.js-resp-title');
      const messageSubtitle = messageCont.querySelector('.js-resp-subtitle');
      messageTitle.innerHTML = resp.output.title;
      messageSubtitle.innerHTML = resp.output.subtitle;
      messageCont.classList.add(`resp-message--${resp.status}`);
      messageCont.classList.add('resp-message--is-active');
      if(resp.status === 'success') {
        setTimeout(function(){
          resetForm(f);
        }, 1000)
      }
      setTimeout(function(){
        messageCont.classList.remove('resp-message--is-active');
      }, 4000)
      setTimeout(function(){
        messageCont.classList.remove('resp-message--success');
        messageCont.classList.remove('resp-message--error');
      }, 5000)
    }

    const badFields = (f, resp) => {
      resp.fields.map((field, i) => {
        const targetField = f.querySelector(`[name="${field.name}"]`);
        const targetContainer = targetField.closest('.form-input');
        targetContainer.querySelector('.form-input__field-message').innerHTML = field.errorMessage;
        targetContainer.classList.add('form-input--has-error');
      });
    }

    const resetForm = (f) => {
      const inputs = f.querySelectorAll('.form-input');
      f.reset();
      for(let i = 0; i < inputs.length; i++) {
        inputs[i].classList.remove('full');
      }
    }
  }
}
