import axios from 'axios';

export default {
  init() {
    const cookieForm = document.querySelectorAll('[data-set-cookie]');

    for (let i = 0; i < cookieForm.length; i++) {
      cookieForm[i].addEventListener('submit', function (e) {
        e.preventDefault();
        const $form = this;
        const formData = new FormData();
        formData.append('cookie', 'set');
        const options = {
          method: $form.dataset.method,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: formData,
          url: $form.dataset.action,
        };

        $form.closest('.cookie').classList.add('is-hidden');

        axios(options)
          .then((response) => {
            console.log('Success response:', response.data);
          })
          .catch((error) => {
            console.log('Error response:', error);
          });
      });
    }
  },
};
