export default {
  init() {
    const lang = document.getElementById('lang-select');
    lang.addEventListener('click', function(e){
      e.stopPropagation();
      this.classList.add('lang-select--is-opened');
    });
    document.body.addEventListener('click', function(e){
      if(lang.classList.contains('lang-select--is-opened')) {
        lang.classList.remove("lang-select--is-opened");
      }
    });
  }
}
