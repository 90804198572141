import Swiper from 'swiper';

export default {
  init() {
    let swiper = new Swiper('#clients-slider', {
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      slidesPerView: 6,
      loop: true,
    });

    document
      .getElementById('logo-slider-next')
      .addEventListener('click', function () {
        swiper.slideNext();
      });

    document
      .getElementById('logo-slider-prev')
      .addEventListener('click', function () {
        swiper.slidePrev();
      });
  },
};
