import '../scss/main.scss';
import ClientsSliderJs from './components/ClientsSlider.js';
import TabsSelectorJs from './components/TabsSelector.js';
import LangSelectJs from './components/LangSelect.js';
import RoadmapSliderJs from './components/RoadmapSlider.js';
import FormsJs from './components/Forms.js';
import Map from './components/Map';
import CookieFormJs from './components/CookieForm.js';
import TablesWrap from './components/TablesWrap';
import MobileMenuJs from './components/MobileMenu.js';
import RevealsJs from './components/Reveals.js';
import ShowMore from './components/ShowMore';
import CountriesMapJs from './components/CountriesMap.js';
import MobileZoomJs from './components/MobileZoom.js';

(function () {
  if (typeof (NodeList as any).prototype.forEach !== 'function') {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }

  return false;
})();

const onLoad = (e: Event) => {
  inits();
  onResize(e);
};

const onResize = (e: Event) => {
  onScroll(e);
};

const onScroll = (e: Event) => {};

const inits = () => {
  const map = new Map('#map');
  FormsJs.inputsEvents();
  FormsJs.initForm();
  CookieFormJs.init();
  TablesWrap.init();
  MobileMenuJs.init();
  RevealsJs.init();
  ShowMore.init();
  if (document.getElementById('countries-map-modal')) {
    MobileZoomJs.init();
  }
  if (document.getElementById('map-svg')) {
    CountriesMapJs.init();
  }
  if (document.getElementById('clients-slider')) {
    ClientsSliderJs.init();
  }
  if (document.getElementById('tabs-selector')) {
    TabsSelectorJs.init();
  }
  if (document.getElementById('lang-select')) {
    LangSelectJs.init();
  }
  if (document.getElementById('roadmap-slider')) {
    RoadmapSliderJs.init();
  }
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
