// import PinchZoom from 'pinch-zoom-js/dist/pinch-zoom.min.js';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  init() {
    var trigger = document.getElementById('map-modal-trigger');
    var modal = document.getElementById('countries-map-modal');
    var modalClose = document.querySelectorAll('.modal__close');
    trigger.addEventListener('click', () => {
      modal.style.display = 'block';
      setTimeout(() => {
        modal.classList.add('is-opened');
      }, 100);
      bodyScrollLock.disableBodyScroll(modal);
    });

    for (let i = 0; i < modalClose.length; i = i + 1) {
      modalClose[i].addEventListener('click', () => {
        modalClose[i].closest('.modal').classList.remove('is-opened');
        setTimeout(() => {
          modalClose[i].closest('.modal').style.display = 'none';
        }, 400);
        bodyScrollLock.enableBodyScroll(modal);
      });
    }
    // new PinchZoom.default(el, {});
    // lightGallery(document.getElementById('mobile-zoom'));
  },
};
