import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

export default {
  init() {
    var roadmapSlider = new Swiper('#roadmap-slider', {
      speed: 400,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '#roadmap-slider-next',
        prevEl: '#roadmap-slider-prev',
      },
      breakpoints: {
        720: {
          slidesPerView: 3,
        },
      },
    });
  },
};
